<template>
  <div>
    <v-dialog max-width="700" v-model="dialogs.cpi">
      <cpi-description @close="dialogs.cpi = false"></cpi-description>
    </v-dialog>
    <v-card outlined max-width="500" class="mx-auto">
      <v-container>
        <download-chart :chart="$refs.chart" prefix="Tax"></download-chart>
        <div class="text-center">
          <div v-if="parcel" class="d-flex justify-space-around">
            <div>
              <span class="parcel-id--color">{{
                parseParcelNumber(parcel.number)
              }}</span>
            </div>
            <div>
              <span
                class="street-address--color"
                v-if="parcel.street_number || parcel.street_name"
              >
                {{ parcel.street_number }} {{ parcel.street_name }}
              </span>
            </div>
          </div>
          <div class="chart-container mx-auto">
            <canvas id="myChart" ref="chart" width="400" height="400"></canvas>
          </div>
        </div>
        <div v-if="taxHistoryVal.length > 1" class="text-center mt-3">
          <p>
            From {{ beginYear }} to {{ endYear }}, Your
            <span class="error--text">Taxes went up by {{ taxGrowth }}%</span
            ><br /><span class="error--text"
              >(from ${{ Intl.NumberFormat().format(beginTax) }} to ${{
                Intl.NumberFormat().format(endTax)
              }})</span
            >
          </p>
          <p>
            During the same period,
            <span class="primary--text"
              >Inflation went up by {{ cpiGrowth }}%</span
            ><br />
            (<span class="primary--text"
              >from {{ beginCPI }} to {{ endCPI }}</span
            >) <br />as measured by the
            <span
              class="cursor-pointer primary--text"
              @click="dialogs.cpi = true"
              ><u>Consumer Price Index</u></span
            >
          </p>
          <p>
            <strong
              >Your Taxes grew {{ taxIncreaseTimes }} times faster than
              Inflation.</strong
            >
          </p>
          <div v-if="taxIncreaseTimes > 1" class="purple--text">
            If these rates of growth continue, your projected Taxes, in today's
            dollars, are estimated to be approximately:

            <div class="d-flex justify-center">
              <ul>
                <li>
                  2025 Taxes ~ {{ estimated[0].tax }}% higher at ${{
                    estimatedTax(estimated[0].tax)
                  }}
                </li>
                <li>
                  2030 Taxes ~ {{ estimated[1].tax }}% higher at ${{
                    estimatedTax(estimated[1].tax)
                  }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js'
import { min } from '../../utils/chart'
import cpiMixin from '../../mixins/cpi'
Chart.register(...registerables)
const taxColor = '#B71C1C'
const cpiColor = 'rgb(20, 93, 202)'
import parcel from '@/mixins/parcel'
import tax from '@/mixins/tax'
export default {
  vm: this,
  mixins: [tax, parcel, cpiMixin],
  components: {
    CpiDescription: () => import('@/components/CPIDescription'),
    DownloadChart: () => import('@/components/Core/DownloadChart.vue')
  },
  props: ['parcel'],
  data: () => ({
    dialogs: {
      cpi: false
    },
    chartData: {},

    context: null,
    chartObj: null
  }),
  mounted() {
    this.setContext()
    this.initChart()
  },
  beforeMount() {
    this.chartData = {
      type: 'line',
      options: {
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: context => {
                let res = ``
                if (!context) {
                  return res
                }
                if (context.dataset.label == 'Taxes') {
                  res = `Taxes: ${context.formattedValue} ${
                    this.isRte(context.dataIndex) ? 'RTE' : ''
                  }`
                } else {
                  res = `${context.dataset.label}: ${context.formattedValue}`
                }
                return res
              }
            }
          }
        },

        y: {
          display: true,
          position: 'left'
        },
        y1: {
          display: true,
          position: 'right'
        },
        scales: {
          y: {
            ticks: {
              callback: function (value) {
                return '$' + value
              },
              color: taxColor
            },
            grid: {
              borderColor: taxColor
            },
            title: {
              display: true,
              text: 'Taxes',
              color: taxColor,
              font: {
                size: '20px'
              }
            }
          },
          y1: {
            ticks: {
              color: cpiColor
            },
            grid: {
              drawOnChartArea: false,
              borderColor: cpiColor
            },
            suggestedMax: null,
            title: {
              display: true,
              text: 'CPI-Consumer Price Index',
              color: cpiColor,
              font: {
                size: '20px'
              }
            }
          }
        }
      },
      data: {
        labels: [],
        datasets: [
          {
            label: 'Taxes',
            fill: false,
            borderColor: taxColor,
            data: [],
            yAxisID: 'y',
            pointBorderWidth: 5,
            pointHoverBorderWidth: 2,
            pointHoverRadius: 5
          },
          {
            label: 'CPI',
            fill: false,
            borderColor: cpiColor,
            data: [],
            yAxisID: 'y1'
          },
          {
            label: 'Trend',
            fill: false,
            borderColor: taxColor,
            pointBorderWidth: 0,
            pointStyle: 'line',
            data: [],
            dashed: true,
            borderDash: [3, 10],
            yAxisID: 'y'
          }
        ]
      }
    }
  },
  computed: {
    taxHistory() {
      return [...this.$store.getters.taxHistory].reverse()
    },
    taxHistoryVal() {
      let res = this.taxHistory.filter(e => {
        return e.cpi && e.tax
      })
      return res
    },
    beginItem() {
      return this.taxHistoryVal[0]
    },
    endItem() {
      return this.taxHistoryVal[this.taxHistoryVal.length - 1]
    },
    beginYear() {
      return this.beginItem.year
    },
    endYear() {
      return this.endItem.year
    },
    beginTax() {
      return this.beginItem.tax
    },
    endTax() {
      return this.endItem.tax
    },
    beginCPI() {
      return this.beginItem.cpi
    },
    endCPI() {
      return this.endItem.cpi
    },
    taxGrowth() {
      return this.percentGrowth(this.beginTax, this.endTax, 0)
    },
    cpiGrowth() {
      return this.percentGrowth(this.beginCPI, this.endCPI, 0)
    },
    taxIncreaseTimes() {
      return (this.taxGrowth / this.cpiGrowth).toFixed(1)
    },
    estimated() {
      let res = []
      let yearsTo2025 = 2025 - this.endYear
      let yearsTo2030 = 2030 - this.endYear
      let yearsRange = this.endYear - this.beginYear
      let netGrowthRate = this.taxGrowth - this.cpiGrowth
      res.push({
        year: 2025,
        tax: ((yearsTo2025 / yearsRange) * netGrowthRate).toFixed(0)
      })
      res.push({
        year: 2030,
        tax: ((yearsTo2030 / yearsRange) * netGrowthRate).toFixed(0)
      })
      return res
    }
  },
  methods: {
    isRte(index) {
      try {
        let data = this.taxHistory[index]
        return data.residentialTaxExemption
      } catch (err) {
        console.log(err)
        return false
      }
    },
    setContext() {
      this.context = document.getElementById('myChart').getContext('2d')
    },
    initChart() {
      this.chartObj = new Chart(this.context, this.chartData)
    },
    generateData() {
      this.chartData.data.labels = []
      this.chartData.data.datasets[0].data = []
      this.chartData.data.datasets[1].data = []
      for (let i of this.taxHistory) {
        if(!i.cpi){
          continue;
        }
        this.chartData.data.labels.push(i.year)
        this.chartData.data.datasets[0].data.push(i.tax)
        this.chartData.data.datasets[1].data.push(i.cpi || this.CPIOf(i.year))
      }
      let minY, maxY, minY2
      minY = min(this.chartData.data.datasets[0].data)
      maxY = Math.max(...this.chartData.data.datasets[0].data)
      minY2 = min(this.chartData.data.datasets[1].data)

      let suggestedMax = (maxY / minY) * minY2
      suggestedMax = Math.ceil(suggestedMax / 5) * 5

      this.chartData.options.scales.y1.suggestedMax = suggestedMax

      this.generateSlopeData()
    },
    generateSlopeData() {
      if (this.taxHistory.length < 5) {
        return
      }
      if (this.taxGrowth < this.cpiGrowth) {
        return
      }
      this.chartData.data.datasets[2].data = []
      let x1, x2, y1, y2
      let data = [...this.taxHistory]
      data = data.filter(e => e.cpi && e.tax)
      x1 = data[0].year
      y1 = data[0].tax
      x2 = data[data.length - 1].year
      y2 = data[data.length - 1].tax
      let slope = (y2 - y1) / (x2 - x1)
      let intercept = y1 - slope * x1
      for (let i of data) {
        this.chartData.data.datasets[2].data.push(slope * i.year + intercept)
      }
    },
    showChart() {
      this.chartObj.update()
    },
    estimatedTax(tax) {
      let estTax = (tax / 100 + 1) * this.endTax
      estTax = Math.round(estTax)

      estTax = Intl.NumberFormat().format(estTax)
      return estTax
    }
  },
  watch: {
    taxHistory() {
      this.generateData()
      this.generateSlopeData()
      this.showChart()
    }
  }
}
</script>

<style>
.chart-container {
  max-width: 500px;
}
</style>
